import React, { useEffect, useState } from 'react';
import AppointmentBookingSuccessModal from '../../components/Modals/AppointmentBookingSuccessModal';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import CalendlyAppointmentBookingSuccessModal from '../../components/Modals/CalendlyAppointmentBookingSuccessModal';
import { useDispatch } from 'react-redux';
import {
  getCalendlyAppointments,
} from '../../store/actions/appointmentAction';
export default function CalendlyBookingSuccessFul() {
  const location = useLocation();
  const [currBookedAppointment, setCurrBookedAppointment] = useState(null);
  const dispatch = useDispatch();
  


  useEffect(()=>{
    const parsedQuery:any = queryString.parse(location.search);
    if(parsedQuery?.calendyEventUrl){
      const finalUrl=parsedQuery?.calendyEventUrl?.replace(
        'calendly.com',
        'api.calendly.com',
      )
    const calendlyAppointmentPromise: any = dispatch(
      getCalendlyAppointments({
        calendlyUrl:finalUrl ,
      }),
    );
    calendlyAppointmentPromise.then((res: any) => {
      let currAppointment= res?.data?.results?.[0];
      let cuurApptFormated:any={
        "event_type_name":currAppointment?.eventName,
        "event_type_uuid":currAppointment?.id,
        "event_start_time":currAppointment?.appointmentStartTime
      }
      setCurrBookedAppointment(cuurApptFormated)
    });
    }else{
      setCurrBookedAppointment(parsedQuery)
    }
  },[])

  return (
    <CalendlyAppointmentBookingSuccessModal
      appointment={currBookedAppointment}
      isModalVisible={true}
      onClose={() => {
        window.location.href= "https://web.uniroma2.it/"
      }}
    />
  );
}
