import React, { Component } from 'react';
import { Modal, Button, Card, Row, Col } from 'react-bootstrap';
import ButtonPrimary from '../../Buttons/ButtonPrimary';
import pendingJson from '../../../assets/lotties/pending.json';
import moment, { Moment } from 'moment';
import paymentSuccessfulJson from '../../../assets/lotties/payment-successful.json';

// Images
import closeIcon from '../../../assets/images/icons/close.svg';
import copyIcon from '../../../assets/images/icons/copy.svg';

import './styles.scss';
import Lottie from 'lottie-react';
import OutlinePrimary from '../../Buttons/OutlinePrimary';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface Props extends RouteComponentProps {
  onClose: any;
  isModalVisible: boolean;
  className?: any;
  primaryHandler?: () => void;
  cancelHandler?: () => void;
  modalTitle?: string;
  modalSubTitle?: string;
  btnText?: string;
  appointment?: any;
}

class CalendlyAppointmentBookingSuccessModal extends Component<Props> {
  render() {
    const {
      onClose,
      isModalVisible,
      className,
      primaryHandler,
      cancelHandler,
      modalSubTitle,
      modalTitle,
      btnText,
      appointment
    } = this.props;
    console.log(appointment)
    return (
      <div className="appointment-booking-success-modal">
        <Modal
          className={`appointment-booking-success-modal-style primary-modal-style ${className}`}
          show={isModalVisible}
          onHide={onClose}
          centered
        >
          <Modal.Header>
            <Modal.Title>Appuntamento confermato!</Modal.Title>
            <Button onClick={onClose} variant="light" className="close-btn">
              <img src={closeIcon} alt="close icon" className="btn-icon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Lottie
                className="appointment-booking-success-modal-lottie"
                animationData={paymentSuccessfulJson}
                loop={true}
              />
            </div>
            <h6 className="title2 text-danger">
              {modalTitle ? modalTitle : 'Appuntamento prenotato correttamente!'}
            </h6>
            
            <Card className="info-card mt-3">
              <h4 className="title5">Informazioni Consulenza:</h4>
              <Row className="mt-2">
                <Col sm="4" className="card-item">
                  <h4 className="label-text">Nome della stanza</h4>
                  <div className="text-dark">
                    {appointment?.event_type_name}
                  </div>
                </Col>

                <Col sm="4" className="card-item">
                  <h4 className="label-text">ID Consulenza</h4>
                  <div className="text-bold">
                    {appointment?.event_type_uuid?.substr(appointment?.event_type_uuid.length - 12)}
                  </div>
                </Col>

                <Col sm="4" className="card-item">
                  <h4 className="label-text">Data & Ora Appuntamento</h4>
                  <div className="text-dark">
                    {moment(
                      appointment?.event_start_time,
                    ).format('LLL')}
                  </div>
                </Col>
              </Row>
            </Card>

            <div className="button-section mt-2 mb-3">
              <OutlinePrimary
                clickHandler={() => {
                  window.location.href= "https://web.uniroma2.it/"
                }}
                btnText="Vai al sito web"
                href="#"
                className="mr-3"
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(CalendlyAppointmentBookingSuccessModal);
