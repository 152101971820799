import React, { Component } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import queryString from 'query-string';
import LeftRoundedCard from '../LeftRoundCard';
import InputPrimary from '../InputPrimary';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import LabelPrimary from '../LabelPrimary';
import { connect } from 'react-redux';
import { login, logout } from '../../store/actions/authAction';
import {
  InlineWidget,
  PopupWidget,
  PopupButton,
  PopupModal,
  useCalendlyEventListener,
} from 'react-calendly';
import { Widget } from '@typeform/embed-react'


// images
import patientIllustration from '../../assets/images/illustration/patient-login-screen.svg';

import './styles.scss';
import { toast } from 'react-toastify';
interface PropsType extends RouteComponentProps {}
interface StateType {
  isLoading: boolean;
  illustration: any;
  bgColor: string;
  userRole: number;
  isOpen: boolean;
  appointmentId:string;
}

const updateState =
  <T extends string>(key: keyof StateType, value: T) =>
  (prevState: StateType): StateType => ({
    ...prevState,
    [key]: value,
  });
class TypeformFirstBooking extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      isLoading: false,
      illustration: patientIllustration,
      bgColor: '#8BDDCD',
      userRole: 0,
      isOpen: false,
      appointmentId:'',
    };
  }

  componentDidMount() {
    const parsedQuery:any = queryString.parse(this.props.location.search);
    this.setState({appointmentId:parsedQuery.appointmentId})
    document.title='Tconsulta | Fai il passo giusto'
  }

  render() {
    const { isLoading, illustration, bgColor, userRole } = this.state;
    return (
      <div
        className="booking-page-container"
        style={{ backgroundColor: bgColor }}
      >
        <Container fluid className="p--0">
          <Row>
            <Col sm={12}>
              <Widget
                hidden={{
                  appointment_id:this.state.appointmentId
                }}
                keepSession
                id={process.env.REACT_APP_TYPEFORM_FIRST_CONSULTATION_FORM || "lLydk8hN"}
                style={{ width: '100%', height: '100vh' }}
                className="my-form"
              />
            </Col>
          </Row>

          {isLoading && (
            <div className="spinner-container not-rounded">
              <div className="spinner"></div>
            </div>
          )}
        </Container>
      </div>
    );
  }
}
const mapDispatchToProps = {};
const mapStateToProps = ({ auth }: { auth: any }) => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TypeformFirstBooking));




