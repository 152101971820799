export const UPDATE_JOYRIDE = 'UPDATE_JOYRIDE';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

export interface updateJoyRide {
    type: typeof UPDATE_JOYRIDE;
    payload: any;
}

export interface updateNotifications {
    type: typeof UPDATE_NOTIFICATIONS;
    payload: any;
}

export type CommonActionTypes = updateJoyRide | updateNotifications;