import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import nextArrow from '../../../assets/images/icons/next-bold.svg';
import './styles.scss';

export interface Props {
  btnText: string;
  iconActive?: boolean;
  href?: any;
  clickHandler?: () => void;
  className?: string;
  isActive?: boolean;
  isLoading?: boolean;
  icon?: string;
}

class OutlinePrimary extends Component<Props> {
  render() {
    const {
      btnText,
      iconActive,
      href,
      clickHandler,
      className,
      isActive,
      isLoading,
      icon,
    } = this.props;
    return (
      <Link
        onClick={(e) => {
          if (isActive === false) {
            e.preventDefault();
            return;
          }
          if (clickHandler) {
            clickHandler();
          }
        }}
        className={`button-outline-style ${className}`}
        // onClick={onClick}
        to={href ? href : '#'}
      >
        {/* {isLoading &&
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        } */}
        {btnText}
        {iconActive ? (
          <img
            src={icon ? icon : nextArrow}
            alt=""
            width="14"
            className="btn-icon"
          />
        ) : null}
      </Link>
    );
  }
}

export default OutlinePrimary;
