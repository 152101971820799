import { UPDATE_JOYRIDE, UPDATE_NOTIFICATIONS, CommonActionTypes } from "../../types/commonActions"

const defaultState: { isUserOnboardingFirstTime: boolean, notifications: any[] } = { isUserOnboardingFirstTime: true, notifications: [] };

export default function (state = defaultState, action: CommonActionTypes): { isUserOnboardingFirstTime: boolean, notifications: any[] } {
    switch (action.type) {
        case UPDATE_JOYRIDE: return {
            ...state,
            isUserOnboardingFirstTime: !state.isUserOnboardingFirstTime,
        }

        case UPDATE_NOTIFICATIONS: return {
            ...state,
            notifications: action.payload,
        }

        default:
            return state
    }

}