import { RequestTypes } from '../../components/ViewDetails';
import { routeConstants } from '../../constants/apiRoutes';
import { SET_APPOINTMENT_METADATA } from '../../types/appointmentActions';

const {
  CANCEL_APPOINTMENT,
  RECHEDULE_APPOINTMENT,
  GET_APPOINTMENTS_VIA_PAYMENT,
  GET_CALENDLY_APPOINTMENTS,
  GET_CALENDLY_USERS,
  UPGRADE_CALENDLY_USER_ACCOUNT,
  GET_TYPEFORM_RESPONSES,
  SEND_NEW_CALENDLY_APPOINTMENT,
  GET_APPOINTMENTS_VIA_CREDITS,
  GET_APPOINTMENTS_VIA_FREE_CREDITS,
  FETCH_APPOINTMENT,
  GET_APPOINTMENT_FOR_SA,

  GET_APPOINTMENTS_IN_LAST_WEEK,
  GET_BASKET_APPOINTMENTS,
  FIRST_APPOINTMENT,
} = routeConstants;

export const cancelAppointment =
  (appointmentId: string) =>
  (dispatch: any, state: any, { api }: any) => {
    // console.log("in the cancel appointment");
    return new api.post(`${CANCEL_APPOINTMENT}/${appointmentId}/cancel`)
      .then((response: { data: any }) => {
        let posts = response.data;
        return posts;
      })
      .catch((err: any) => {
        return err;
      });
  };
export const rescheduleAppointment =
  (appointmentId: any, slotId: any, type?: RequestTypes, isReschedulingViaRequest:boolean=false) =>
  (dispatch: any, state: any, { api }: any) => {
    return new api.post(RECHEDULE_APPOINTMENT.replace(':id', appointmentId), {
      slotId: slotId,
      type: type,
      isReschedulingViaRequest
    })
      .then((response: { data: any }) => {
        let posts = response.data;
        return posts;
      })
      .catch((err: any) => {
        throw err;
      });
  };

export const saveAppointmentData =
  (payload: any) =>
  (dispatch: any, state: any, { api }: any) => {
    // console.log('KARDIA BOOK - 1', payload)
    // setTimeout(
    //   dispatch({
    //     type: SET_APPOINTMENT_METADATA,
    //     payload: payload,
    //   }),
    //   1500,
    // );
    dispatch({
      type: SET_APPOINTMENT_METADATA,
      payload: payload,
    });
    return Promise.resolve();
  };

export const getAppointmentViaPayment =
  (query: any) =>
  (dispatch: any, state: any, { api }: any) => {
    // console.log('query', query);
    return new api.get(GET_APPOINTMENTS_VIA_PAYMENT, { params: query }).then(
      (res: any) => {
        // console.log('respinse ::: ', res);
        return res ? res.data : undefined;
      },
    );
  };


export const fetchAppointment =
  (caseId: string) =>
  (dispatch: any, state: any, { api }: any) => {
    // console.log('caseId', caseId);
    return new api.get(FETCH_APPOINTMENT, { params: { caseId: caseId } })
      .then((res: any) => {
        if (parseInt(res.status) === 200) {
          // console.log(res);
          return res.data;
        }
      })
      .catch((err: any) => {
        throw err;
      });
  };

export const getAppointmentForSa =
  (query: any) =>
  (dispatch: any, state: any, { api }: any) => {
    // console.log(query);

    return new api.get(GET_APPOINTMENT_FOR_SA, { params: query })
      .then((res: any) => {
        return res.data;
      })
      .catch((err: any) => {
        throw err;
      });
  };

export const getAppointmentsInLastWeek =
  () =>
  (dispatch: any, state: any, { api }: any) => {
    return new api.get(GET_APPOINTMENTS_IN_LAST_WEEK)
      .then((res: any) => {
        if (parseInt(res.status) === 200) {
          return res.data;
        } else {
          // console.log(res);
          throw res.error;
        }
      })
      .catch((err: any) => {
        throw err;
      });
  };

export const getBasketAppointments =
  (id: string) =>
  (dispatch: any, state: any, { api }: any) => {
    return new api.get(GET_BASKET_APPOINTMENTS.replace(':id', id))
      .then((res: any) => {
        if (parseInt(res.status) === 200) {
          return res.data;
        } else {
          // console.log(res);
          throw res.error;
        }
      })
      .catch((err: any) => {
        throw err;
      });
  };

  export const isFirstBasketAppointment =
  (id: string) =>
  (dispatch: any, state: any, { api }: any) => {
    return new api.get(FIRST_APPOINTMENT)
      .then((res: any) => {
        if (parseInt(res.status) === 200) {
          return res.data;
        } else {
          // console.log(res);
          throw res.error;
        }
      })
      .catch((err: any) => {
        throw err;
      });
  };

  export const fetchAppointmentsViaCredits =(query:any) =>
  (dispatch:any,state:any,{api}:any)=>{
    return new api.get(GET_APPOINTMENTS_VIA_CREDITS,{params:query}).then((res:any)=>{
      return res ? res.data : undefined;
    })
  }

  export const fetchAppointmentsViaFreeCredits =(query:any) =>
  (dispatch:any,state:any,{api}:any)=>{
    return new api.get(GET_APPOINTMENTS_VIA_FREE_CREDITS,{params:query}).then((res:any)=>{
      return res ? res.data : undefined;
    })
  }

  export const getCalendlyAppointments =
  (query?: any) =>
  (dispatch: any, state: any, { api }: any) => {
    return new api.get(`${GET_CALENDLY_APPOINTMENTS}`, { params: query })
      .then((response: { data: any }) => {
        let posts = response.data;
        return posts;
      })
      .catch((err: any) => {
        return err;
      });
  };

  export const getCalendlyUsers =
  (query?: string) =>
  (dispatch: any, state: any, { api }: any) => {
    return new api.get(`${GET_CALENDLY_USERS}`, { params: query })
      .then((response: { data: any }) => {
        let posts = response.data;
        return posts;
      })
      .catch((err: any) => {
        return err;
      });
  };

  export const upgradeCalendlyAccount =
    (calendlyUserId?: string) =>
    (dispatch: any, state: any, { api }: any) => {
      return new api.patch(`${UPGRADE_CALENDLY_USER_ACCOUNT}`, {
        calendlyUserId,
      })
        .then((response: { data: any }) => {
          return response;
        })
        .catch((err: any) => {
          return err;
        });
    };

  export const getTypeformResponses =
    (appointmentId: string) =>
    (dispatch: any, state: any, { api }: any) => {
      return new api.get(
        `${GET_TYPEFORM_RESPONSES.replace(':appointmentId', appointmentId)}`,
      )
        .then((response: { data: any }) => {
          return response.data;
        })
        .catch((err: any) => {
          throw err;
        });
    };

  export const sendNewCalendlyAppointment =
    (calendlyUserId: string) =>
    (dispatch: any, state: any, { api }: any) => {
      return new api.post(`${SEND_NEW_CALENDLY_APPOINTMENT}`, { calendlyUserId })
        .then((response: { data: any }) => {
          return response;
        })
        .catch((err: any) => {
          throw err;
        });
    };